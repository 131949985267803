// import { createBrowserRouter } from "react-router-dom";
import Login from "../component/views/login/login";
import Dashboard from "../component/views/dashboard/index";
import Home from "../component/views/dashboard/home/home";
import User from "../component/views/dashboard/users";
import AllMargin from "../component/views/allMargin"
import ReceivedPayments from "../component/views/allMargin/receivedPayments";
import CarrierPayments from "../component/views/allMargin/carrierPayments";
import BottomMenu from "../component/layouts/bottomMenu";
import ArAging from "../component/views/ar/aging-report/agingReport";
import BusinessReport from "../component/views/ar/companies/businessReport";
import BrokerBusinessReport from "../component/views/broker/businessReport";
import InrPayments from "../component/views/broker/inrPayments.jsx";
import InrPaymentsDetail from "../component/views/broker/inrPaymentsDetail.jsx";
import ApAging from "../component/views/ap/aging-report/agingReport";
import Dsr from "../component/views/broker/dsr/dsr";
import DsrTl from "../component/views/broker/dsr/dsrTl";
import DsrBroker from "../component/views/broker/dsr/dsrBroker";
import DsrMonthly from "../component/views/broker/dsr/dsrMonthly.tsx";
import DsrMonthlyTeam from "../component/views/broker/dsr/dsrMonthlyTeam";

import CarrierPayment from "../component/views/ap/carrier-payment/carrierPayment";
import ConfirmSingalPayment from "../component/views/ap/carrier-payment/confirmSingalPayment";
import DownloadCsv from "../component/views/ap/carrier-payment/downloadCsv.js";
import ConfirmPayment from "../component/views/ap/carrier-payment/confirmPayment";
import Tmdsr from "../component/views/broker/dsr/tmdsr";
// import Dashboard from "../pages/users";
import {MdHome} from 'react-icons/md'

const Router = [
  {
    name: "Home",
    label: "Home",

    layout: "/home",
    path: "/",
    icon: <MdHome className="h-6 w-6" />,
    display: true,
    component: <Home />
  },
  {
    name: "dashboard",
    label: "Dashboard",

    layout: "/dashboard",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    display: true,
    component: <Dashboard />
  },
  {
    name: "allMargin",
    label: "All Margin Report",

    layout: "/allMargin",
    path: "margin-reports",
    icon: <MdHome className="h-6 w-6" />,
    display: true,
    component: <AllMargin />
  },
  {
    name: "user",
    label: "User",

    layout: "/user",
    path: "user",
    icon: <MdHome className="h-6 w-6" />,
    display: true,
    component: <User />
  },
  {
    name: "received-payments",
    label: "Received Payments",

    layout: "margin-reports/received-payments",
    path: "margin-reports/received-payments/:date",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <ReceivedPayments />
  },
  {
    name: "carrier-payments",
    label: "Carrier Payments",
    layout: "margin-reports/carrier-payments",
    path: "margin-reports/carrier-payments/:date",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <CarrierPayments />
  },
  {
    name: "bm",
    label: "Carrier Payments",
    layout: "bottom-menu",
    path: "bottom-menu",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <BottomMenu />
  },
  {
    name: "Ar Business Report",
    label: "Ar Business Report",
    layout: "ar-business-report",
    path: "ar/business-report",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <BusinessReport />
  },
  {
    name: "Broker Business Report",
    label: "Br Business Report",
    layout: "broker-business-report",
    path: "broker/business-report",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <BrokerBusinessReport />
  },
  {
    name: "ArAging",
    label: "Ar Aging",
    layout: "ar-aging",
    path: "ar-aging",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <ArAging />
  },
  {
    name: "ApAging",
    label: "Ap Aging",
    layout: "ap-aging",
    path: "ap-aging",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <ApAging />
  },
  {
    name: "DSR",
    label: "DSR",
    layout: "dsr",
    path: "dsr",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <Dsr />
  },
  {
    name: "DSR",
    label: "DSR",
    layout: "dsr",
    path: "dsr/tl/:id",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <DsrTl />
  },
  {
    name: "DSR",
    label: "DSR",
    layout: "dsr",
    path: "dsr/broker/:id",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <DsrBroker />
  },
  {
    name: "DsrMonthly",
    label: "DSR Monthly",
    layout: "dsr-monthly", 
    path: "dsr/monthly",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <DsrMonthly />
  },
  {
    name: "DsrMonthly",
    label: "DSR Monthly",
    layout: "dsr-monthly", 
    path: "dsr/monthly/:for/:id",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <DsrMonthlyTeam />
  },
  {
    name: "CarrierPayment",
    label: "Carrier Payment",
    layout: "carrier-payment", 
    path: "ap/carrier-payment",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <CarrierPayment />
  }
  ,
  {
    name: "ConfirmPayment",
    label: "Confirm payment",
    layout: "confirm-payment", 
    path: "ap/confirm-payment",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <ConfirmPayment />
  }
  ,
  {
    name: "ConfirmPayment",
    label: "Confirm single payment",
    layout: "confirm-single-payment", 
    path: "ap/confirm-single-payment/:id",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <ConfirmSingalPayment />
  }
  ,
  {
    name: "DownloadCsv",
    label: "Download csv",
    layout: "download-csv", 
    path: "ap/download-csv",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <DownloadCsv />
  }
  ,
  {
    name: "inrPayments",
    label: "INR Payments",
    layout: "inr-payments", 
    path: "broker/inr-payments",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <InrPayments />
  }
  ,
  {
    name: "inrPaymentsDetail",
    label: "INR Payments Detail",
    layout: "inr-payments-detail", 
    path: "broker/inr-payments/detail/:user_id",
    icon: <MdHome className="h-6 w-6" />,
    display: false,
    component: <InrPaymentsDetail />
  }
  
];

export default Router;
