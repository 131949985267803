import React, { useState,useEffect,useRef } from "react";

import { Link,useParams,useNavigate  } from "react-router-dom";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import Skalaton from "../../../dataTables/skalaton";

import { useHookstate } from '@hookstate/core'
import Store from '../../../../store';
import Moment from 'moment';
import {carrierPaymentAPI,apGetDisputesAPI,apSetPaymentAPI,apHoldPaymentAPI} from "../../../../config/endpoints";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";

export default function CarrierPayment() {
  const closeRef = useRef();
  const gState = useHookstate(Store);
  const navigate = useNavigate();
  
  const [filter, setFilter] = useState({
    pro:'',
    method:'',
    company:'',
    carrier:'',
    ages:'',
    broker:'',
    status:''
  });

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({status:'',reason:'',shipment_id:''});
  const [formDataError, setFormDataError] = useState({status:'',reason:'',shipment_id:''});
  const [totalPendings, setTotalPendings] = useState(0);
  const [totalAging, setTotalAging] = useState(0);
  // const [total, setTotal] = useState(0);
  const [disputeData, setDisputeData] = useState({payment_hold:[],aging_hold:[], shipment_id:''});
  const [selectedPros, setSelectedPros] = useState([]);
  const [sum, setSum] = useState(0);
  const [selectedProCount, setSelectedProCount] = useState(0);
  
  const date = gState.fullDate.get();




      useEffect(() => {

        getData();

      },[date,filter]);
// 
      useEffect(() => {
        calculate();
      },[selectedPros]);
      
      const calculate = ()=>{

        let totalSum  = 0;
         data.map(element=>{
            if(selectedPros.includes(String(element.shipment_Id))){
              // alert(totalSum);

              totalSum  += parseInt ((element.payment_mode=="USD" || element.primery_method == "ACH")?element.payable_amount:((element.payable_amount_cad!=null)?element.payable_amount_cad:element.carrier_total_cad));
            }

          });
          // console.log(data);
          console.log(selectedPros);
          setSum(totalSum);
        }
     

      const getData = ()=>{
          setData([]);
          axios.post(carrierPaymentAPI,{
              withCredentials:true,
              date:date,
              filter:filter
          })
            .then(async function (response) {
              // console.log(response.data.data);
              setData(response.data.data)
              setTotalAging(response.data?.CarriersAgingDue?.total)
              setTotalPendings(response.data?.pendingTotal)
              
               setSelectedPros(response.data.selectedPros);
               setSelectedProCount(response.data.selectedPros.length);
              calculate();
            }).catch(function (error) {
              console.log(error);
            });
      }
      const getDisputes = (id)=>{
          axios.post(apGetDisputesAPI,{
              withCredentials:true,
              shipment_id:id,
          })
            .then(function (response) {
              setFormData({status:response.data.payment.payment_hold,reason:'',shipment_id:id});
              setDisputeData(response.data);
              // console.log(formData);
              console.log(formData);
            }).catch(function (error) {
              console.log(error);
            });
      }

      


      const selectedPro = (e) =>{
        let val = e.target.value;
        const arr = selectedPros;
        const index = arr.indexOf(val);
        

          if (index > -1) {
            arr.splice(index, 1);
            
            setSelectedProCount(selectedProCount-1);
            e.target.checked = false
          }else{
            if(arr.length>100){
              alert("maximum 100 pro's can be selected");
              e.target.checked = false;
            }else{
              arr.push(val);
              setSelectedProCount(selectedProCount+1);
              e.target.checked = true
            }
          }
          // console.log(arr);
            setSelectedPros(arr);
            calculate();
        


      }
      const Payment = () =>{
        axios.post(apSetPaymentAPI,{
            withCredentials:true,
            data:selectedPros,
        })
        .then(function (response) {
          navigate("/admin/ap/confirm-payment");
          
              
        }).catch(function (error) {
          console.log(error);
        });
      }
      const ResetSelected = () =>{
        axios.post(apSetPaymentAPI,{
            withCredentials:true,
            data:[],
        })
        .then(async function (response) {
           getData();
          
              
        }).catch(function (error) {
          console.log(error);
        });
      }

      const holdPayment = (e) =>{
        e.preventDefault();

        if(formData.reason.length < 3){
          setFormDataError({...formDataError,reason: 'Minimum 3 characters are required.'});
          return;
        }
        setFormDataError({...formDataError,reason: ''});

        // return;
        axios.post(apHoldPaymentAPI,formData,{
            withCredentials:true,
            // data:formData,
        })
        .then(async function (response) {
          // console.log(response.data);
          toast.success('status updated successfully', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getData();
          closeRef.current.click();
          
          
        }).catch(function (error) {
          console.log(error);
        });
      }

  return (
    <>

            <div className="all_pages_body" >
            
            <ToastContainer />

              <div className='container option_sticky pt-1' style={{zIndex:8}}>
              
                <div className="card bg-white border-0">
                  <div className="card-body">
                    
                    <div className="row">
                      <div className="col-md-6 text-start"> 
                          <h3 className="main-title pt-1">Carrier Payment</h3>
                          
                         {'robn"s house'.replace(/"/g, "\"")}
                      </div>
                      <div className="col-md-6 ">
                      {selectedProCount>0? <h6 className="float-start"> Total: <span className="fw-lighter">$</span><b>{Number(sum).toLocaleString('en-IN')}</b></h6>:''}

                      {/* <button type="button" className="btn btn-primary">
                        Launch demo modal
                      </button> */}


                          {/* <input  type="date" className="shadow-sm px-2 py-1 border-0 float-end" id="filter-date" value={date} onChange={(e) => gState.fullDate.set(Moment(e.target.value).format('YYYY-MM-DD'))} /> */}
                          {(selectedProCount>0)?
                            <>
                              <button  className="btn btn-success btn float-end me-3" download={true} onClick={()=>{Payment()}}>Paynow</button>
                              <button  className="btn btn-warning btn float-end me-3" download={true} onClick={()=>{ResetSelected()}}>Reset Selection</button>
                            </>
                          :'' }
                          
                      </div>
                      <div className="col-md-12">
                        <div className="mt-2 d-flex flex-wrap">
                          {selectedPros.map((e)=>{
                            return( <div className="amount_bedge"> {e}</div>)
                            })}
                        </div> 
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div className='container mt-2 py-0'>
                  <div className="row">
                    <div className="col-12">
                      <div className="card border-0 shadow ar_scroll_table">
                        <div className="card-body  bg-white rounded ">
                            <div className="container shadow pb-1 px-0">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="vehicles-overview-progress progress rounded-0 rounded-top  my-0" style={{"height": "30px"}}>
                            
                                  <div className="progress-bar fw-medium text-dark fs-6  px-4" role="progressbar" style={{"width": "25%","background":"#f76e5f"}} aria-valuenow="39.7" aria-valuemin="0" aria-valuemax="100"><span className="text-start">TOTAL RESULT:<span className="float-end">({data.length})</span></span></div>
                                  <div className="progress-bar fw-medium  fs-6 px-4" role="progressbar " style={{"width": "25%",'background':"#9e63f0"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"><span className="text-start">TOTAL PENDING: <span className="float-end">${Number(totalPendings).toLocaleString('en-IN')}</span></span></div>
                                  <div className="progress-bar fw-medium  fs-6 px-4" role="progressbar " style={{"width":"25%","background":"#73d298"}} aria-valuenow="35" aria-valuemin="0" aria-valuemax="Aging"><span className="text-start">AGING DUE: <span className="float-end">${Number(totalAging).toLocaleString('en-IN')}</span></span></div>
                                  <div className="progress-bar fw-medium fs-6   px-lg-2 px-xxl-3 px-4" role="progressbar" style={{"width": "25%",'background':'#486e76'}} aria-valuenow="14.6" aria-valuemin="0" aria-valuemax="100"><span className="text-start">TOTAL: <span className="float-end">${Number((totalPendings+totalAging)).toLocaleString('en-IN')}</span></span></div>  
                                </div>
                              </div>
                            </div>
                                <div className="table-responsive table_main">
                                    <table className="table table0 accordion text-sm text-center  " >
                                        <thead className="ar_table_sticky">
                                            <tr>
                                                <th scope="col" className="text-sm" style={{width:"4%"}}  >#</th>
                                                <th scope="col" className="text-sm" style={{width:"4%"}}   >SL</th>
                                                <th scope="col" className="text-sm" style={{width:"11%"}} >Pro</th>
                                                <th scope="col" className="text-sm" style={{width:"13%"}} >Pay Method</th>
                                                <th scope="col" className="text-sm" style={{width:"25%"}} >Company Name</th>
                                                <th scope="col"className="text-sm" >Broker Name</th>
                                                <th scope="col" className="text-sm" >Ages</th>
                                                <th scope="col"className="text-sm" >Carrier Rate</th>
                                                <th scope="col"className="text-sm" >Action</th>
                                                <th scope="col"className="text-sm" >Action</th>
                                            </tr>
                                            <tr>
                                                <th scope="col" ></th>
                                                <th scope="col" ></th> 
                                                <th scope="col" ><input className="rounded border border-secondary form-control-xs" placeholder="Pro No" style={{width:"70%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,pro: e.target.value}))}} />
                                               
                                                </th>
                                                <th scope="col" ><select className="rounded border border-secondary form-control-xs"  style={{width:"70%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,method: e.target.value}))}} >
                                                <option value="">All</option>
                                                  <option value="ACH">ACH</option>
                                                  <option value="Wire">Wire</option>
                                                  <option value="cheque">Cheque</option>
                                                  <option value="EFS">EFS</option>
                                                  </select>
                                                </th>
                                                <th scope="col" ><input className="rounded border border-secondary form-control-xs" placeholder="company Name" style={{width:"70%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,company: e.target.value}))}} /></th>
                                                <th scope="col" ><input className="rounded border border-secondary form-control-xs " placeholder="Broker Name" style={{width:"90%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,broker: e.target.value}))}} /></th>
                                                <th scope="col" ><select className="rounded border border-secondary form-control-xs"  style={{width:"70%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,ages: e.target.value}))}} >
                                                  <option value="">All</option>
                                                  <option value="30">0-30</option>
                                                  <option value="60">30-60</option>
                                                  <option value="90">60-90</option>
                                                  <option value="90+">90+</option>
                                                </select>
                                                </th>
                                                <th scope="col" ></th>
                                                <th scope="col" ></th>
                                                <th scope="col" ><select className="rounded border border-secondary form-control-xs"   style={{width:"70%"}} onChange={(e)=>{setFilter((prevState) => ({...prevState,status: e.target.value}))}} >
                                                  <option value="">All</option>
                                                  <option value="1">Disputed</option>
                                                  <option value="2">Payable</option>
                                                </select>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                        { (data.length<1)?
                                        <Skalaton rows="10" cols="10" />
                                        :data.map(function(q,l){
                                          let rowColor = (q.payment_mode=="USD" || q.primery_method == "ACH")?"text-light-primary":"text-dark-warning";
                                        return (
                                            <tr key={l}   >
                                                <td >{ q.payable_amount>0 && q.payable_amount_cad!=0  && q.payment_hold != 1 && q.primery_method == "ACH"?
                                                <input className="check-lg" type="checkbox" value={q.shipment_Id} onChange={(e)=>{ selectedPro(e);}} defaultChecked={q.selected}  />:'' }</td>  
                                                <td>{l+1} </td>
                                                <td width="10%"   >
                                                <Link   to={{ pathname: `/admin/ap/confirm-single-payment/${q.shipment_Id}` }} className={rowColor}>
                                                  {q.shipment_Id}
                                                </Link>
                                                  </td>
                                                <td width="10%" className="text-secondary" >{q.primery_method!=null?q.primery_method:'N/A'}</td>
                                                <td width="25%" className={rowColor} >{ q.payto=="1"?q.f_companyName:(q.payto=="2"?q.c_company_name:(q.company_name!=null?q.company_name:'N/A'))}</td> 
                                                <td width="10%" className="text-secondary" >{q.first_name!=null?q.first_name:q.brokername}</td>
                                                <td  className="text-secondary" >{q.Age_date}</td>
                                                <td  className={rowColor}>${q.payment_mode=="USD" || q.primery_method == "ACH"?((Number(q.payable_amount).toLocaleString('en-IN'))+' USD'):((q.payable_amount_cad!=null)?(Number(q.payable_amount_cad).toLocaleString('en-IN')):(Number(q.carrier_total_cad).toLocaleString('en-IN')))+' CAD' }</td>
                                                <td  >
                                                  {(q.assign_status != '1' && (q.payment_hold == '2'))?(
                                                  (q.payment_status == '0')?
                                                    <Link to={{ pathname: `/admin/ap/confirm-single-payment/${q.shipment_Id}` }} class="btn panding_btn btn-sm text_sm" data-toggle="modal" data-target="#pendingpayment" data-shipment="<?php echo $RefNo; ?>" data-carrierid="<?php echo $CarrierResultDD->carriers_Id;?>" data-PaymentStatus="<?php echo $CarrierResultDD->payment_status;?>" >Pending</Link>
                                                    :((q.payment_status == '1')? <a href="javascript:void(0)" style="background: #556080;text-decoration:none" class="payment_complete sendmail" data-toggle="modal" data-target="#payment_complete" data-shipment="<?php echo $RefNo; ?>" data-carrierid="<?php echo $CarrierResultDD->carriers_Id;?>" >Done</a>:''))
                                                    :''}
                                                </td>
                                                <td  >
                                                  {q.payment_hold==1?
                                                  <button className="btn btn-outline-danger btn-sm text_sm" value={q.shipment_Id}  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>{getDisputes(e.target.value)}}  >Disputed</button>
                                                  :
                                                  <button className="btn btn-outline-warning btn-sm text_sm btn_min_width" value={q.shipment_Id}  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e)=>{getDisputes(e.target.value);  }}  >Hold</button>
                                                  // <Link  className="btn btn-outline-success btn-sm" to={{ pathname: `/admin/ap/confirm-single-payment/${q.shipment_Id}` }}>Pay Now
                                                  // </Link>
                                                  }
                                                </td>
                                            </tr>)
                                        })}
                                         <tr> <td colSpan={10} className="py-3 border-0"></td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{zIndex:999}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Payment Hold #({disputeData.shipment_id})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={holdPayment}>
            <div className="modal-body">
              {
              disputeData.payment_hold.map((element,i) => {
                  
                 return( <div key={i} className="row">
                    <div className="col-11 border-start border-warning ms-4 position-relative ps-4">
                    <span class="badge rounded-pill bg-danger text-danger timeline-pill">1</span>
                      <small  class="badge rounded-pill bg-danger" >{Moment(new Date (element.updated_at)).format('MM/DD/YYYY' )}</small>
                      <p className="py-3 text-purple">{element.reason}</p>
                    </div>
                    
                  </div>)
              })}
              {disputeData?.aging_hold.length>0?
                <div className="row">
                    <div className="col-12 ">
                    {/* <span class="badge rounded-pill bg-danger timeline-pill">Danger</span> */}
                      <h1>Aging Hold</h1>
                    </div>
                </div>:''}
                {
                disputeData.aging_hold.map((element,i) => {
                    
                  return( <div key={i} className="row">
                      <div className="col-11 border-start border-warning ms-4 position-relative ps-4">
                      <span class="badge rounded-pill bg-danger text-danger timeline-pill">1</span>
                        <small  class="badge rounded-pill bg-danger" >{Moment(new Date (element.updated_at)).format('MM/DD/YYYY' )}</small>
                        <p className="py-3 text-purple">{element.reason}</p>
                      </div>
                      
                    </div>)
                })}
                {/* {disputeData?.aging_hold.length>0 ||  */}
                <hr style={{"height":"2px",color:'gray',background:'gray'}}></hr>
              <div className="mt-3">
                <div className="">
                  {/* <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" style={{width:'100px',height:'40px'}}/>
                    <label className="form-check-label mt-2" for="flexSwitchCheckDefault"> &nbsp;&nbsp; Default switch checkbox input</label>
                  </div> */}
                      <label for="exampleFormControlTextarea1 mx-2" className="form-label">switch</label>

                  <div className=" ">

                      <label class="switch">
                        <input type="checkbox" data-id={formData.shipment_id }data-status={formData.status} checked={formData.status=="1"?true:false}  onChange={(e)=>{setFormData({...formData,status: e.target.checked?'1':'2'});}} />
                        <div class="slider round">
                          <span class="on">DISPUTED</span>
                          <span class="off">RESOLVED</span>
                        </div>
                        {/* Reason */}
                      </label>
                  </div>
                </div>

                <div className="py-3 form-group">
                  <label for="exampleFormControlTextarea1" className="form-label">Reason</label>
                  <textarea className="form-control" value={formData.reason} placeholder="Reason" id="exampleFormControlTextarea1" rows="3" 
                  onChange={(e)=>{setFormData({...formData,reason: e.target.value});}}></textarea>
                  <span className="text-danger">{formDataError?.reason}</span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit"  className="btn btn-primary">Save changes</button>
              <button ref={closeRef} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </form>
          </div>
        </div>
      </div>
            </>
  )
}


