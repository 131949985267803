import React, {useEffect,useRef,useState} from 'react';
import "./bottom.css";

import "./backgroundAnimation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link,useLocation,useNavigate} from 'react-router-dom';

import { logoutAPI} from "../../config/endpoints";
import axios from "axios";

import {
  faHouse,
  faSackDollar,
  faLaptopFile,
  faFileInvoiceDollar,
  faRightFromBracket,
  faMoneyCheckDollar,
  faMoneyBillTrendUp,
  faChartPie,
  faChevronRight,
  faChevronLeft,
  faChartLine,
  faFileCsv,
  faIndianRupeeSign,
  faCoins,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";

const BottomMenu = () => {
  let ref = useRef();
  
  const navigate = useNavigate();
 const [active, setActive] = useState();
 const location = useLocation();
//  const  getpath = ()=> {
    
//     console.log(location.pathname);
//     return location.pathname;
//   }
  
  const handleOnClick = (e) => {

    var offsetValue = (ref.current.offsetWidth/2)-(e.target.closest('.scroll_container_sub').offsetWidth/2);
    // console.log(e.target.closest('.scroll_container_sub').offsetWidth/2);
    ref.current.scrollTo({
      top: 0,
      left:e.target.closest('.scroll_container_sub').offsetLeft-offsetValue,
      behavior: 'smooth'
    })

}
const logOut = ()=>{
    axios.post(logoutAPI,{
        withCredentials:true,
      }).then(function (response) {
        navigate("/");
      }).catch(function (error) {});
}

  const handleScroll = (e) => {
        // console.log(ref.current.querySelector('.scroll_bg_section'));
        let maincontainer = ref.current.querySelector('.scroll_bg_section').offsetWidth/3;
        // console.log(maincontainer.offsetWidth);
        e.preventDefault();
        let deltaY =  parseInt(e.deltaY);
        if ( deltaY > 0){ 
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft + maincontainer,
                behavior: 'smooth'
            })
            
        }
        else {         
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft - maincontainer,
                behavior: 'smooth'
            })
        }
    }
  const clickScroll = (to) => {
        let maincontainer = ref.current.querySelector('.scroll_bg_section').offsetWidth/3;
        if ( to == 'L'){ 
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft - maincontainer,
                behavior: 'smooth'
            })
            
        }
        else {         
            ref.current.scrollTo({
                top: 0,
                left:ref.current.scrollLeft + maincontainer,
                behavior: 'smooth'
            })
        }
    }
useEffect(() =>{
    ref.current.addEventListener("wheel", handleScroll);
},[])
    return (
<>
        {/* <div class="fixed-button">
          
          <a href="#" class=""><span className='icon_menu'>v</span> <span className='icon_menu_name'>naya</span></a>
        </div> */}
        <div class="fixed-button">
          
        <Link to="/vnaya/"><span className='icon_menu'>v</span> <span className='icon_menu_name'>naya</span></Link>
        </div>
    <div className='abc'>
      <div className="scroll_container" ref={ref}  >
        <div className='scroll_bg_section'>
            <div className='d-flex justify-content-between position-relative'>
                <div className='position-absolute left-arrow-icon' onClick={()=>{ clickScroll('L') }} >
                    <FontAwesomeIcon icon={faChevronLeft} className="text-dark-warning mx-1 arrow_icon" />
                </div>
                <div className='position-absolute right-arrow-icon'  onClick={()=>{ clickScroll('R') }}  >
                    <FontAwesomeIcon icon={faChevronRight} className="text-dark-warning mx-1 arrow_icon" />
                </div>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/ar-aging'?'active':'')}  onClick={()=>setActive('w')}>
                <Link to="/admin/ar-aging">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faMoneyCheckDollar} className="text-dark-warning mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">AR Aging Report</p>
                </Link>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/ar/business-report'?'active':'')}  onClick={()=>setActive('admin/ar/business-report')}>
                <Link to="/admin/ar/business-report">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faChartLine} className="text-light-primary mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text"><small>Business Report</small></p>
                </Link>
            </div>
        </div>
        
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/broker/business-report'?'active':'')}  onClick={()=>setActive('admin/broker/business-report')}>
                <Link to="/admin/broker/business-report">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faMoneyBillTrendUp} className="text-dark-warning mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text"><small>Broker Business </small></p>
                </Link>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/broker/inr-payments'?'active':'')}  onClick={()=>setActive('admin/broker/inr-payments')}>
                <Link to="/admin/broker/inr-payments">
                    <div className='icon' style={{}}>
                        
                    <FontAwesomeIcon icon={faIndianRupeeSign} className="text-dark-warning mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text"><small>Broker INR Margin </small></p>
                </Link>
            </div>
        </div>

        <div className="scroll_container_sub text-dark position-relative"  >
            <div className={'nav-content '+ (location.pathname=='/admin/margin-reports'?'active':'')}  >
                <Link to="/admin/margin-reports">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-dark-pink mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Margin Report</p>
                </Link>
                
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/user'?'active':'')}  onClick={()=>setActive('q')}>
                <Link to="/admin/user">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faChartPie} className="text-light-success mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Employee Chart</p>
                </Link>
            </div>
        </div>
        
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin'?'active':'')}  onClick={()=>setActive('w')}>
                <Link to="/admin">
                    <div className='icon' style={{}}>
                        <FontAwesomeIcon icon={faHouse} className="text-dark-warning mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Home</p>
                </Link>
            </div>
        </div>
        {/* <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/ap-aging'?'active':'')}  onClick={()=>setActive('e')}>
                <Link to="/admin/ap-aging">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-light-success mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">AP Aging Report</p>
                </Link>
            </div>
        </div>
       
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/dsr/monthly'?'active':(active=='/admin/dsr/monthly'?'active':''))}  onClick={()=>setActive('/admin/dsr/monthly')}>
                <Link to="dsr/monthly">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faMoneyBillTrendUp} className="text-dark-pink mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Monthly Margin </p>
                </Link>
            </div>
        </div> */}
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/ap/carrier-payment'?'active':(active=='/admin/ap/carrier-payment'?'active':''))}  onClick={()=>setActive('/admin/ap/carrier-payment')}>
                <Link to="ap/carrier-payment">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faSackDollar} className="text-light-primary mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Carrier Payment </p>
                </Link>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/ap/download-csv'?'active':(active=='/admin/ap/download-csv'?'active':''))}  onClick={()=>setActive('/admin/ap/download-csv')}>
                <Link to="/admin/ap/download-csv">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faFileCsv} className="text-light-primary mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Payments History </p>
                </Link>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ (location.pathname=='/admin/dsr'?'active':(active=='/admin/dsr'?'active':''))}  onClick={()=>setActive('/admin/dsr')}>
                <Link to="/admin/dsr">
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faLaptopFile} className="text-dark-warning mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">DSR </p>
                </Link>
            </div>
        </div>
        <div className="scroll_container_sub text-dark position-relative" onClick={handleOnClick} >
            <div className={'nav-content '+ ((active=='logout'?'active':''))}  onClick={()=>setActive('logout')}>
                <a  href='javascript:void(0)' onClick={logOut}>
                    <div className='icon' style={{}}>
                    <FontAwesomeIcon icon={faRightFromBracket} className="text-dark-pink mx-1 menu_icon" />
                    </div>
                    <p className="main-nav__item-text">Logout </p>
                </a>
            </div>
        </div>
       

        
    </div>
       
  </div>
  {/* <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        </div> */}
  </>
    );
}

export default BottomMenu;
