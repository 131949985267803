import React, { useRef, useEffect, useState } from "react";
import { useHookstate } from '@hookstate/core'
import { d3API,brokerMarginDetailAPI,tlMarginDetailAPI,tmMarginDetailAPI,brokerTotalMarginDetailAPI } from '../../config/endpoints';
import Store from '../../store';
import axios from "axios";
// libraries
import * as d3 from "d3";
// redux
// import { connect } from "react-redux";

const Dthree = ({date}) => {
  let tt = 1;
    const gState = useHookstate(Store);
   
  const shipperDetail = gState.d3data.get();
  // const [shipperDetail,setShipperDetail] = useState({});
    const [data,setData] = useState({
        "name": "flare",
        "children": []});
  
        useEffect(() => {
        axios.post(d3API,{
            withCredentials:true,
            date:date
        })
          .then(function (response) {
            setData(response.data)

          })
          .catch(function (error) {
            // console.log(error);
          });
        },[date]);
       


    const width = 600;
    const height =600;
    const radius = width / 6;
    const svgRef = useRef(null);
    const svgContainer = useRef(null);


    // calculate width and height of container
  const getSvgContainerSize = () => {
    const newWidth = width;
    const newHeight = height;
  };

  // resizer
  useEffect(() => {
    axios.post(brokerTotalMarginDetailAPI,{
      withCredentials:true,
      date:date,
  })
      .then(function (response) {
          gState.d3data.set(response.data);
      })
      .catch(function (error) {
          console.log(error);
      });
    // get 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes
    window.addEventListener("resize", getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener("resize", getSvgContainerSize);
  }, [data]);



    // Create the color scale.
    const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));
//   console.log(color);
    // Compute the layout.
    
    const hierarchy = d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);
    const root = d3.partition()
        .size([2 * Math.PI, hierarchy.height + 1])
      (hierarchy);
    root.each(d => d.current = d);
  
    // Create the arc generator.
    const arc = d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))
  
    // Create the SVG container.
    const svg = d3.select(svgRef.current)
    .classed("sunburst-chart-svg", true)
        .attr("viewBox", [-width / 2, -height / 2, width, width])
        .style("font", "10px sans-serif");


    useEffect(() => {
      let aaa = svg.select("g").selectAll("text").filter((d, i) => i === 1);
      aaa.text("$"+(Number(shipperDetail?.lockedPendingIncentive?.margin??0)+Number(shipperDetail?.lockedIncentive?.margin??0)).toFixed(2).toLocaleString('en-IN'))
    },[shipperDetail]);

    useEffect(() => {
    
      
    

       svg.selectAll("*").remove();
        // everything.remove();
    // Append the arcs.
    const path = svg.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
        .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
        .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
        .attr("pointer-events", d => arcVisible(d.current) ? "auto" : "none")
  
        .attr("d", d => arc(d.current));
      // alert(data);
      // console.log(data);
       svg.select("g").append("text")
             .style("text-anchor", "middle")
            .text('Total Margin')
            .style("font-size","2em");
       svg.select("g").append("text")
             .style("text-anchor", "middle")
            // .text('₹'+Number((data.totalInrIncentive??0).toFixed(0)).toLocaleString('en-IN'))
            .text("$"+(Number(shipperDetail?.lockedPendingIncentive?.margin??0)+Number(shipperDetail?.lockedIncentive?.margin??0)).toFixed(2).toLocaleString('en-IN'))
            .style("font-size","14px")
            .attr("y", 20);

        svg.select("g").append("svg").html(`<svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" fill="currentColor" className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16"><path fill="#76e7cc91" fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/><path fill="#76e7cc91" d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/></svg>`)
        .style("text-anchor", "middle")
            .attr("y", -90)
            .attr("x", -90);

            path.filter(d => d.current)
            // .style("cursor", "pointer")
            .on("click", getdetail);
    path.filter(d => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);
   

    const format = d3.format(",d");
  //       path.append("title")
  // // .style("position", "absolute")
  // // .style("z-index", "10")
  // // .style("visibility", "hidden")
  // // .style('font-size', '50px')
  // // .style('color', 'red')
  // .html(d => `<h1>Margin: ${d.data.name}\n Total: ${(d.data.total ||=0).toFixed(2)}\n Received: ${(d.data.received ||=0).toFixed(2)}\n Pending: ${(d.data.pending ||=0).toFixed(2)}<h1>`);

  // define Tooltip  #######################################
  // let preTooltip = d3.select('.tootltip'); 
  // console.log(preTooltip?.innerHTML);
      path.append('div')
      .html(d => `<p class="fs-5 my-0 ">${d.data.name}</p> <hr> <ul><li> <b>Total:</b>  <span class='float-end'>$ <b> ${Number((d.data.total ||=0).toFixed(2)).toLocaleString('en-IN')}</b></span></li> <li> <b>Received:</b> <span class='float-end'>$ <b> ${Number((d.data.received ||=0).toFixed(2)).toLocaleString('en-IN')}</b></span> </li> <li> <b>Pending:</b>  <span class='float-end'>$ <b> ${Number((d.data.pending ||=0).toFixed(2)).toLocaleString('en-IN')}</b></span></li>  <li> <b>Incentive:</b>  <span class='float-end'>₹<b> ${Number(d.data.final_inr).toLocaleString('en-IN')}</b></span></li> </ul>`)
      document.getElementById('tooltips')?.remove();
    var tooltip = d3.select('body') 
      .append('div').classed('tooltip', true);   
      tooltip.attr('id', 'tooltips');   
      tooltip.style('position', 'absolute'); 
      tooltip.style('width', '255px'); 
      tooltip.style('opacity', 1);
      tooltip.style('background', '#fffffff5');
      tooltip.style('box-shadow', '0px 0px 20px 0px #80808073');
      path.on('mouseover', function(d) {
        let tdata = d.target.innerHTML;
        tooltip.html(`${tdata}`);                
        tooltip.style('display', 'block'); 
        tooltip.style('top', d.pageY + 'px'); 
        tooltip.style('left', d.pageX + 'px');
      })
      .on('mouseout', function(e) {
               tooltip.style('display', 'none');
      })

  // End Tooltip  #######################################
  
    const label = svg.append("g")
    
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .style("font-size", "14px")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      
        .attr("dy", "0.35em")
        .attr("fill-opacity", d => +labelVisible(d.current))
        .attr("transform", d => labelTransform(d.current))
        .text(function(d){

            return `${d.data.name}`;
            // return `${d.data.name}${(!d.data.total)?'':(': '+(d.data.total).toFixed(1))}`;
        });
   
   
    const parent = svg.append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("click", clicked);

        function getdetail(event, p) {
            parent.datum(p.parent || root);
            console.log(p);
            // axios.defaults.withCredentials = true
            if(p.data.user_id){
                let detailUrl = brokerMarginDetailAPI;
                let detailType = 'Individual';
                if(p.data.user_id == p.data.tl_id && p.depth == 2){
                    detailUrl = tlMarginDetailAPI;
                    detailType = 'TL Team';
                }else if(p.data.user_id == p.data.manager_id &&  p.depth == 1){
                    detailUrl = tmMarginDetailAPI;
                    detailType = 'Manager Team';
                }
            axios.post(detailUrl,{
                withCredentials:true,
                date:date,
                user_id:p.data.user_id
            })
                .then(function (response) {
                  let resdata = response.data;
                  // if(response.data){
                    // response.data.detailType = detailType;
                    // setShipperDetail(response.data);
                  // }
                  resdata.detailType = detailType;
                    gState.d3data.set(resdata);
                })
                .catch(function (error) {
                    console.log(error);
                });

            }else{
              let detailUrl = brokerTotalMarginDetailAPI;
              axios.post(detailUrl,{
                withCredentials:true,
                date:date,
                user_id:p.data.user_id
            })
                .then(function (response) {
                    gState.d3data.set(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
            } 
        }

        function clicked(event, p) {
            parent.datum(p.parent || root);
            getdetail(event, p);
            // console.log(p.data);
            // axios.defaults.withCredentials = true
            // if(p.data.user_id){
            //     let detailUrl = 'http://localhost:3001/api/broker-margin-detail';
            //     if(p.data.user_id == p.data.tl_id){
            //         detailUrl = 'http://localhost:3001/api/tl-margin-detail';
            //     }else if(p.data.user_id == p.data.manager_id){
            //         detailUrl = 'http://localhost:3001/api/tm-margin-detail';
            //     }
            // axios.post(detailUrl,{
            //     withCredentials:true,
            //     date:date,
            //     user_id:p.data.user_id
            // })
            //     .then(function (response) {
            //         gState.d3data.set(response.data);
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });

            // }else{
            //     // marginDetail({});
            // }
           
            root.each(d => d.target = {
              x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth)
            });
        
            const t = svg.transition().duration(750);
        
            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", d => {
                  const i = d3.interpolate(d.current, d.target);
                  return t => d.current = i(t);
                })
              .filter(function(d) {
                return +this.getAttribute("fill-opacity") || arcVisible(d.target);
              })
                .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none") 
        
                .attrTween("d", d => () => arc(d.current));
        
            label.filter(function(d) {
                return +this.getAttribute("fill-opacity") || labelVisible(d.target);
              }).transition(t)
                .attr("fill-opacity", d => +labelVisible(d.target))
                .attrTween("transform", d => () => labelTransform(d.current));
          }
          
    },[data]); // redraw chart if data or dimensions change
  
    // Handle zoom on click.
    
    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }
  
    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }
  
    function labelTransform(d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  
    const renderContent = () => {
        return (
          <div className="sunburst-chart-container">
            {/* <div ref={svgContainer} className="sunburst-chart-svg-container"> */}
              <svg ref={svgRef}  style={{"height": "640px"}} />
            {/* </div> */}
          </div>
        );
      };
    
      return <>{renderContent()}</>;
};

export default Dthree;